import Proptypes from 'prop-types';

import styles from './FeatureCard.module.scss';

function FeatureCard({ Icon, title, description }) {
  return (
    <div className={styles.container}>
      <div>
        <Icon />
      </div>
      <p>{title}</p>
      <p>{description}</p>
    </div>
  );
}

FeatureCard.propTypes = {
  Icon: Proptypes.elementType.isRequired,
  title: Proptypes.string.isRequired,
  description: Proptypes.string.isRequired,
};

export default FeatureCard;
