import PropTypes from 'prop-types';
import ReactEChart from 'echarts-for-react';

const options = {
  animation: false,
  xAxis: {
    type: 'category',
    show: false,
  },
  yAxis: {
    type: 'value',
    min: 'dataMin',
    show: false,
  },
  grid: {
    left: 0,
    bottom: '35%',
    show: false,
  },
  color: '#FFD140',
};
const serie = {
  type: 'line',
  showSymbol: false,
  soomth: true,
  lineStyle: {
    width: 1,
  },
  areaStyle: {
    normal: {
      color: {
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [
          {
            offset: 0,
            color: '#FFD140',
          },
          {
            offset: 0.7,
            color: 'transparent',
          },
        ],
        globalCoord: false,
      },
    },
  },
};

function SimpleEChart({ data }) {
  return (
    <ReactEChart
      option={{ ...options, series: [{ ...serie, data }] }}
      style={{
        width: '100%',
        height: '60px',
        position: 'relative',
        top: '-20px',
      }}
    />
  );
}

SimpleEChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.number),
};
SimpleEChart.defaultProps = {
  data: [],
};

export default SimpleEChart;
