import { useMemo } from 'react';

import useRequest from './useRequest';

const requestConfig = {
  url: '/api/index.htm?action=carousel',
};

function usePromoteData() {
  const { data, error } = useRequest(requestConfig);
  const result = useMemo(() => {
    if (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return { done: true, error };
    }
    if (data) {
      return { done: true, data: data.carousels };
    }
    return {};
  }, [data, error]);
  return result;
}

export default usePromoteData;
