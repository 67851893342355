import { useMemo } from 'react';
import { useStore } from '@/utils/store';

const useSymbolList = (symbol) => {
  const { tradeList = {}, rate: rates = {} } = useStore().symbol;
  const symbolInfo = useMemo(() => {
    if (!tradeList[symbol] || !rates) return undefined;
    const { currency } = tradeList?.[symbol] || {};
    const rate = rates[currency];
    return { rate, ...(tradeList?.[symbol] || {}) };
  }, [tradeList, rates]);

  return symbolInfo;
};

export default useSymbolList;
