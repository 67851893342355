import {
  useMemo, useCallback,
} from 'react';
import { useStore } from '@/utils/store';
import useBitcastListData from './useBitcastListData';
import { dayChangeRate } from '../functions/math';
import rest from '../functions/rest';
import { getTradeListName } from '../functions/common';

const rateSorter = (a, b) => Number(b.rate) - Number(a.rate);
// const volumeSorter = (a, b) => Number(a.sellVolume) + Number(a.buyVolume) - Number(b.sellVolume) - Number(b.buyVolume);
const volumeSorter = (a, b) => parseFloat(b.volume) * parseFloat(b.price) - parseFloat(a.volume) * parseFloat(a.price);
const useQuoteTableData = () => {
  const saveInfo = useStore().savedInfo;
  const { user } = useStore().auth;
  const { quote } = useStore().websocket;
  const { groups, codes, tradeList } = useStore().symbol;
  const bitcastObject = useBitcastListData();
  const isOpenV = useCallback(
    (contract) => {
      if (!tradeList[contract.code]) return contract;
      // todo: add isOpen
      return contract;
    },
    [tradeList],
  );

  const quoteTableData = useMemo(() => {
    if (!quote || !groups || !codes || !tradeList) {
      return {
        tabList: [[], [], [], [], []],
        marketTableList: [[], [], [], []],
        spotArr: [[], [], [], [], [], [], [], [], [], [], [], [], []],
        marginArr: [[], [], [], []],
      };
    }
    const quoteWithRate = Object.entries(tradeList).map(([key, value]) => {
      let obj = {};
      if (!quote[value.contractCode]) {
        obj.buyPrice = '0.0';
        obj.buyVolume = '0.0';
        obj.isUp = '1';
        obj.max = '0.0';
        obj.min = '0.0';
        obj.prev = '0.0';
        obj.price = '0.0';
        obj.sellPrice = '0.0';
        obj.sellVolume = '0.0';
        obj.volume = '0.0';
        obj.socketData = false;
      } else {
        obj = quote[value.contractCode];
        obj.socketData = true;
      }
      obj.name = value.name ? getTradeListName(value.name?.replace(value?.currency, '')) : value.code;
      obj.originName = value.name ? getTradeListName(value.name?.replace(value?.currency, ''), { imageSrc: true }) : '';
      obj.isOpen = rest.isOpening(value);
      obj.rate = dayChangeRate(obj.prev, obj.price);
      obj.isUp = obj.isUp > 0;
      obj.id = key;
      obj.code = value.code;
      obj.symbol = value.contractCode;
      obj.des = value.des;
      obj.crypto = value.crypto;
      obj.currency = value.currency;
      obj.contractCode = value.contractCode;
      return {
        ...obj,
      };
    }).filter(
      (e) => e?.id?.indexOf('3S') === -1
        && e?.id?.indexOf('3L') === -1
        && e?.id?.indexOf('TWT') === -1
        && e?.id?.indexOf('TWN') === -1
        && e?.id?.indexOf('TMT') === -1,
    );
    // const quoteWithRatea = Object.entries(quote).map(([key, value]) => {
    //   const rate = dayChangeRate(value.prev, value.price);
    //   const code = codes.contractCode[key];
    //   const isUp = value.isUp > 0;
    //   const name = code
    //     && (tradeList[code]?.name.includes('USD') ? tradeList[code].name : tradeList[code].code).replace(
    //       tradeList[code].currency,
    //       '',
    //     );
    //   const isOpen = rest.isOpening(tradeList[codes.contractCode[key]]);
    //   const des = tradeList[codes.contractCode[key]]?.des;
    //   const crypto = tradeList[codes.contractCode[key]]?.crypto;
    //   const currency = tradeList[codes.contractCode[key]]?.currency;
    //   return {
    //     ...value,
    //     isOpen,
    //     des,
    //     crypto,
    //     currency,
    //     rate,
    //     name,
    //     isUp,
    //     code,
    //     symbol: key,
    //   };
    // });
    const all = quoteWithRate.filter((item) => groups?.全部?.includes(item.code));
    const main = quoteWithRate.filter((item) => groups?.主流区?.includes(item.code));
    const hot = quoteWithRate.filter((item) => groups?.市值榜?.includes(item.code));
    const rateSortedQuotes = all.sort(rateSorter);
    const volumeSortedQuotes = main.map((item) => isOpenV(item)).sort(volumeSorter);
    const raise = rateSortedQuotes.slice(0, 10);
    const fall = rateSortedQuotes.slice(-10).reverse();
    const fresh = quoteWithRate
      .filter((item) => groups?.NEW?.includes(item.code))
      .sort(rateSorter)
      .slice(0, 10);
    const volume = volumeSortedQuotes.slice(0, 10);
    const symbols = Array.from(new Set([...all, ...main].map((symbolQuote) => symbolQuote.symbol)));

    // faves
    const faveDb = JSON.parse(localStorage.getItem('favor_db')) || [];
    let faveCodes = '';
    if (!user) {
      faveCodes = 'd';
    } else {
      faveCodes = user.userId;
    }
    if (faveDb[faveCodes] === undefined) faveCodes = 'd';
    const faveSpot = quoteWithRate.filter((item) => faveDb[faveCodes]?.CT?.includes(item.code)).map((item) => {
      const newObj = { ...item };
      newObj.faveType = 'CT';
      return newObj;
    }).sort((a, b) => (a.name > b.name ? 1 : -1));

    const faveMargin = quoteWithRate.filter((item) => faveDb[faveCodes]?.FT?.includes(item.code)).map((item) => {
      const newObj = { ...item };
      newObj.faveType = 'FT';
      return newObj;
    }).sort((a, b) => (a.name > b.name ? 1 : -1));

    let faveBitcast = [];
    if (faveDb[faveCodes]?.B) {
      faveBitcast = faveDb[faveCodes]?.B.map((item) => item.split('__')).map((arr) => {
        const obj = quoteWithRate.filter((item) => arr[0]?.includes(item.code)) || {};
        if (obj[0]) {
          const newObj = { ...obj[0] };
          // eslint-disable-next-line prefer-destructuring
          newObj.interval = arr[1];
          newObj.faveType = 'B';
          return newObj;
        }
        return obj[0];
      }).filter((item) => item).sort((a, b) => (a.name > b.name ? 1 : -1));
    }

    const fave = faveSpot
      .concat(faveMargin)
      .concat(faveBitcast)
      .filter((item) => item)
      .sort((a, b) => (a.name > b.name ? 1 : -1));

    // spot
    const spot = quoteWithRate.filter((item) => (groups?.数字货币?.includes(item.code) || groups?.全部?.includes(item.code)) && item.code.includes('_CC'))
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    const gray = quoteWithRate.filter((item) => groups?.Gray?.includes(item.code))
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    const defi = quoteWithRate.filter((item) => groups?.Defi?.includes(item.code))
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    const pos = quoteWithRate.filter((item) => groups?.POS?.includes(item.code))
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    const pow = quoteWithRate.filter((item) => groups?.POW?.includes(item.code))
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    const metaverse = quoteWithRate.filter((item) => groups?.METAVERSE?.includes(item.code))
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    const nft = quoteWithRate.filter((item) => groups?.NFT?.includes(item.code))
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    const polka = quoteWithRate.filter((item) => groups?.Polkadot?.includes(item.code))
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    const storage = quoteWithRate.filter((item) => groups?.存储?.includes(item.code))
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    const layers2 = quoteWithRate.filter((item) => groups?.LAYER2?.includes(item.code))
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    const bsc = quoteWithRate.filter((item) => groups?.BSC?.includes(item.code))
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    const animal = quoteWithRate.filter((item) => groups?.ANIMAL?.includes(item.code))
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    // margin
    const marginSpecialList = quoteWithRate.filter((item) => groups?.数字货币?.includes(item.name))
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    const marginList = quoteWithRate.filter((item) => groups?.数字货币?.includes(item.code) && !(item.code.includes('_CC')))
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    let deliveryItems = [];
    let ustockItems = [];
    saveInfo?.zones.forEach((item) => {
      if (item.code === 'deriv') {
        deliveryItems = [...new Set(item.list.split(';'))];
      } else if (item.code === 'ustock') {
        ustockItems = [...new Set(item.list.split(';'))];
      }
    });
    const deliveryList = quoteWithRate.filter((item) => deliveryItems.includes(item.code))
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    const ustockList = quoteWithRate.filter((item) => ustockItems.includes(item.code))
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    const crypto = [...marginSpecialList, ...marginList];
    const margin = [...crypto, ...deliveryList, ...ustockList];
    // isOpen
    Object.values(tradeList).forEach((item) => {
      // eslint-disable-next-line no-param-reassign
      item.isOpen = rest.isOpening(item);
    });

    // bitcast
    const bitcastAllList = [];
    bitcastObject?.data?.forEach((item) => {
      bitcastAllList.push(item.code);
    });
    const bitcastList = [...new Set(bitcastAllList)];
    const bitcast = quoteWithRate.filter((item) => bitcastList.includes(item.code))
      .sort((a, b) => (a.name > b.name ? 1 : -1));

    return {
      tabList: [raise, fall, fresh, volume, hot],
      symbols,
      marketTableList: [fave, spot, margin, bitcast],
      favourites: [fave, faveSpot, faveMargin, faveBitcast],
      spotArr: [faveSpot, spot, gray, defi, pos, pow, metaverse, nft, polka, storage, layers2, bsc, animal],
      marginArr: [faveMargin, crypto, deliveryList, ustockList],
      tradeList,
      bitcastCommodity: bitcastObject,
    };
  }, [quote, groups, codes, tradeList]);

  return { ...quoteTableData };
};

export default useQuoteTableData;
