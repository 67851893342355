/* eslint-disable no-console */

import { useMemo } from 'react';
import { useStore } from '@/utils/store';

import useRequest from './useRequest';

const requestConfig = {
  url: 'mkpai/thirtyDayVolume',
};

function useIndexData() {
  const store = useStore()?.savedInfo;
  const { data, error } = useRequest({
    // baseURL: store?.quoteDomainsUrl[0],
    baseURL: store?.quoteDomainsUrl[0] || 'https://test-qts.bskbacekfjd.com/',
    url: requestConfig.url,
  });

  const result = useMemo(() => {
    if (error) {
      console.error(error);
      return { done: true, error };
    }
    if (data) {
      return {
        done: true,
        data: data.data,
      };
    }
    return {};
  }, [data, error]);
  return result;
}

export default useIndexData;
