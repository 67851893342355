import usePromoteData from '@/utils/hooks/usePromoteData';

import styles from './Promotions.module.scss';

function Promotions() {
  const { data = [] } = usePromoteData();
  const promotions = data.filter((banner) => banner.expireTime >= Date.now() && banner.platform.includes('web'));

  return (
    <div className={styles.container}>
      {promotions.map((promotion) => (
        <div key={promotion.id} className={styles.promotionCard}>
          <a href={promotion.link || '#'}>
            <img src={promotion.url} alt={promotion.name} />
          </a>
        </div>
      ))}
    </div>
  );
}

export default Promotions;
