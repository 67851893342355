import PropTypes from 'prop-types';
import classnames from 'classnames';
import Image from 'next/image';
import { useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'next-i18next';

import { useStore } from '@/utils/store';
import reRouteTo from '@/utils/api/sessionPass';
import SimpleEChart from '@/components/common/Chart/SimpleEchart';

import styles from './QuoteTable.module.scss';

function Skeleton() {
  return (
    <>
      {new Array(10).fill('').map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <tr key={`skeleton-${index}`} className={styles.skeleton}>
          <td>
            <span className={styles.name} />
          </td>
          <td>
            <span className={styles.chart} />
          </td>
          <td>
            <span className={styles.rate} />
          </td>
          <td>
            <span className={styles.price} />
          </td>
        </tr>
      ))}
    </>
  );
}
function Quote({ quote, isReverse, chartData }) {
  const [imgFallback, setImgFallback] = useState(false);
  const color = isReverse ? !quote.isUp : quote.isUp;
  return (
    <>
      <td className={styles.name}>
        {imgFallback ? (
          <span className={styles.fallbackIcon}>{quote.name[0]}</span>
        ) : (
          <figure>
            <Image
              layout="fill"
              src={`https://nbt-ui.oss-cn-hongkong.aliyuncs.com/bt-ui/vmicon/${quote.name}.svg`}
              alt={quote.name}
              onError={() => setImgFallback(true)}
            />
          </figure>
        )}
        {quote.name}
      </td>
      <td className={styles.chart}>
        <SimpleEChart data={chartData} />
      </td>
      <td className={classnames(styles.rate, { [styles.raise]: color })}>{`${quote.isUp ? '+' : ''}${quote.rate}%`}</td>
      <td className={styles.price}>{quote.price}</td>
    </>
  );
}
function QuoteTable({ quotes, title, chartData }) {
  const { t } = useTranslation('pages', { keyPrefix: 'home.market.table' });
  const { isReverse } = useStore().setting;

  const handleRedirectTo = useCallback((e) => {
    const { dataset } = e.currentTarget;
    reRouteTo(dataset.path);
  }, []);

  const renderQuote = useMemo(
    () => quotes.map((quote) => (
      <tr
        data-path={`/trade/deal/${quote.code}`}
        onClick={handleRedirectTo}
        key={`${title}-${quote.code}`}
        className={styles.quoteRow}
      >
        <Quote quote={quote} isReverse={isReverse} chartData={chartData[quote.symbol]} />
      </tr>
    )),
    [quotes],
  );

  return (
    <div className={styles.container}>
      <p className={styles.title}>{title}</p>
      <table className={styles.table}>
        <colgroup>
          <col className={styles.nameCol} />
          <col className={styles.chartCol} />
          <col span="2" className={styles.priceCol} />
        </colgroup>
        <thead>
          <tr className={styles.header}>
            <th scope="col">{t('name')}</th>
            <th scope="col">{t('trend')}</th>
            <th scope="col">{t('24diff')}</th>
            <th scope="col">{t('price')}</th>
          </tr>
        </thead>
        <tbody>{quotes.length ? renderQuote : <Skeleton />}</tbody>
      </table>
    </div>
  );
}

export const quotePropTypes = {
  price: PropTypes.string,
  rate: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  isUp: PropTypes.bool,
  symbol: PropTypes.string,
};

Quote.propTypes = {
  quote: PropTypes.shape(quotePropTypes).isRequired,
  chartData: PropTypes.arrayOf(PropTypes.number),
  isReverse: PropTypes.bool.isRequired,
};
Quote.defaultProps = {
  chartData: [],
};
QuoteTable.propTypes = {
  quotes: PropTypes.arrayOf(PropTypes.shape(quotePropTypes)).isRequired,
  title: PropTypes.string.isRequired,
  chartData: PropTypes.instanceOf(Object),
};
QuoteTable.defaultProps = {
  chartData: {},
};

export default QuoteTable;
