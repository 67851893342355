import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import useNewsData from '@/utils/hooks/news/useNoticeData';
import moment from 'moment';
import { getPlatform } from '@/utils/functions/common';
import { useRouter } from 'next/router';
import useH5StringBuilder from '@/utils/hooks/useH5StringBuilder';
import reRouteTo from '@/utils/api/sessionPass';
import SideBarIcon from '../center/imgs/SideBarIcon';
import styles from './NewsBanner.module.scss';

function NewsBanner() {
  const router = useRouter();
  const { t } = useTranslation('pages', { keyPrefix: 'news' });
  const { data } = useNewsData();
  const { phrase, referral } = useH5StringBuilder();

  function duplicateArrayToNine(arr) {
    if (!arr) return [];

    let a = arr.filter((notice) => notice.top === true);
    if (a.length > 9) {
      a = a.filter((item, i) => i < 9);
    }

    if (a.length === 3 || a.length === 2) {
      a = a.concat(a);
    }

    const b = a.shift();
    a = [].concat(a).concat(b).filter((notice) => notice);

    return a;
  }
  const newData = useMemo(() => duplicateArrayToNine(data), [data]);
  const styleName = useMemo(() => `carousel${newData.length}`, [newData]);
  const routeFunction = (notice) => {
    if (getPlatform() === 'pad' || getPlatform() === 'pc') {
      router.push(`/news/announcement/${notice?.id}`);
    } else {
      reRouteTo(`${phrase}/notice${referral}`);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={`${styles.carousel} ${styles[styleName]}`}>
        {(newData || []).map((notice, index) => (
          <div
            key={`notice.title${index + 1}`}
            className={styles.carouselItem}
            onClick={() => routeFunction(notice)}
            role="presentation"
          >
            <div className={styles.carouselHead}>
              <SideBarIcon icon="announcement" />
            </div>
            <div className={styles.carouselBody}>
              <div className={styles.indexTitle}>
                <span>{`【${t(`announcement.type.${notice?.type}`)}】`}</span>
                <span>{notice?.title}</span>
              </div>
              <div className={styles.index}>
                <div className={styles.date}>{`${moment(notice?.time).format('YYYY/MM/DD')}`}</div>
                {/* <div className={styles.more}>{'More >'}</div> */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default NewsBanner;
