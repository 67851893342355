import { useMemo } from 'react';

import useRequest from '../useRequest';

const requestConfig = {
  url: '/api/discover/index.htm?type=1,2,3,4,5,6,7,8',
};

function useNoticeData() {
  const { data, error } = useRequest(requestConfig);
  const result = useMemo(() => {
    if (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return { done: true, error };
    }
    if (data) {
      return {
        done: true,
        data: (data?.notices || []).sort((a, b) => {
          if (a.top === true && b.top === false) return -1;
          if (a.top === false && b.top === true) return 1;
          if (a.time < b.time) return 1;
          return -1;
        }),
      };
    }
    return {};
  }, [data, error]);
  return result;
}

export default useNoticeData;
