import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import { getPlatform } from '@/utils/functions/common';
import { useStore } from '@/utils/store';
import useSymbolInfo from '@/utils/hooks/useSymbolInfo';
import { dayChangeRate } from '@/utils/functions/math';
import useH5StringBuilder from '@/utils/hooks/useH5StringBuilder';

import styles from './QuickQuote.module.scss';

const Quote = observer(({ symbol }) => {
  const symbolInfo = useSymbolInfo(symbol);
  const { currency: localCurrency } = useStore().symbol;
  const { isReverse } = useStore().setting;
  const { quote } = useStore().websocket;
  const paddingData = !symbolInfo || !quote;
  const quoteData = useMemo(() => {
    if (paddingData) return undefined;
    const {
      name, currency, contractCode, rate: exchangeRate,
    } = symbolInfo;
    const symbolQuote = quote?.[contractCode] || {};
    const isUp = symbolQuote.isUp > 0;
    const title = `${name.replace(currency, '')}/${currency}`;
    const rate = `${symbolQuote.isUp ? '+' : ''}${dayChangeRate(symbolQuote.prev, symbolQuote.price)}%`;
    const { price } = symbolQuote;
    const fiat = `${localCurrency === 'CNY' ? '¥' : '$'}${Number((symbolQuote.price * (exchangeRate || 1)).toFixed(8))}`;
    return {
      title,
      isUp: isReverse ? !isUp : isUp,
      price,
      rate,
      fiat,
    };
  }, [symbolInfo, quote, paddingData]);

  return quoteData ? (
    <div className={styles.datas}>
      <img src={`https://nbt-ui.oss-cn-hongkong.aliyuncs.com/bt-ui/vmicon/${quoteData.title.replace('/USDT', '')}.svg`} alt="" />
      <div>
        <span className={styles.symbol}>{quoteData.title}</span>
        <div className={styles.price}>
          <span>
            $
            {quoteData.price}
          </span>
          <span className={classnames(styles.priceRate, { [styles.rise]: quoteData.isUp })}>{quoteData.rate}</span>
        </div>
      </div>
    </div>
  ) : (
    <Skeleton />
  );
});

Quote.propTypes = {
  symbol: PropTypes.string.isRequired,
};

function Skeleton() {
  return (
    <div className={styles.skeleton}>
      <div className={styles.symbol} />
      <div className={styles.price} />
    </div>
  );
}

function QuickQuote() {
  const symbolStore = useStore().symbol;
  const group = symbolStore.groups?.['市值榜'] || [];
  const [currentWidth, setCurrentWidth] = useState(0);
  const { user } = useStore().auth;
  const { phrase, referral } = useH5StringBuilder();

  useEffect(() => {
    const updateWindowWidth = () => {
      setCurrentWidth(window.innerWidth);
    };
    window.addEventListener('resize', updateWindowWidth);
    updateWindowWidth();
    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  function createSubarrays(originalArray, displaySize) {
    const newArray = [];
    for (let i = 0; i < originalArray.length; i += displaySize) {
      newArray.push(originalArray.slice(i, i + displaySize));
    }
    return newArray;
  }

  function jumpToPage(data) {
    let finalUrl = '';

    if (getPlatform() === 'pad' || getPlatform() === 'pc') {
      if (user) {
        finalUrl = `/trade/${data.includes('_CC') ? 'spot' : 'deal'}/${data}`;
      } else {
        finalUrl = `/trade/${data.includes('_CC') ? 'spotV2' : 'margin'}/${data}`;
      }
    } else {
      finalUrl = `${phrase}/${data.includes('_CC') ? 'spotQuote' : 'trade'}/${data}${referral}`;
    }
    window.location.href = finalUrl;
  }

  const list = useMemo(() => {
    let displaySize = 4;
    if (currentWidth < 420) {
      displaySize = 1;
    } else if (currentWidth < 768) {
      displaySize = 2;
    } else if (currentWidth < 1024) {
      displaySize = 3;
    } else {
      displaySize = 4;
    }

    const listArray = createSubarrays(group, displaySize);
    return listArray;
  }, [group, currentWidth]);

  const renderQuote = (list || []).map((row) => (
    <div key={`quote-row-${row[0]}`}>
      <div className={styles.quoteRow}>
        {row.map((symbol) => (
          <div
            key={`quote-${symbol}`}
            className={styles.quoteBrick}
            role="button"
            onClick={() => jumpToPage(symbol)}
            onKeyDown={() => jumpToPage(symbol)}
            tabIndex={0}
          >
            <Quote symbol={symbol} />
          </div>
        ))}
      </div>
    </div>
  ));

  const renderSkeleton = useMemo(() => (
    <div className={classnames(styles.quoteList, styles.quoteRow)}>
      {new Array(4).fill('').map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`skeleton-${index}`}>
          <Skeleton />
        </div>
      ))}
    </div>
  ));

  return (
    <div className={styles.container}>
      {list.length ? (
        <Slider
          infinite
          autoplay
          // vertical
          // verticalSwiping
          swipeToSlide
          draggable={false}
          slidesToShow={1}
          slidesToScroll={1}
          className={styles.quoteList}
          arrows={false}
        >
          {renderQuote}
        </Slider>
      ) : (
        renderSkeleton
      )}
    </div>
  );
}

export default observer(QuickQuote);
