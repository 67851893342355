/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */

import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Slider from 'react-slick';
import { observer } from 'mobx-react';

import { getPlatform } from '@/utils/functions/common';
// import reRouteTo from '@/utils/api/sessionPass';
import { useStore } from '@/utils/store';
import useQuoteTableData from '@/utils/hooks/useQuoteTableData';
import { useTranslation } from 'react-i18next';
import useH5StringBuilder from '@/utils/hooks/useH5StringBuilder';

import styles from './TopGainers.module.scss';

const Quote = observer((props) => {
  const { data, page, first } = props;
  const { quote } = useStore().websocket;
  const paddingData = !quote;
  const { isReverse } = useStore().setting;
  const { t } = useTranslation('pages', { keyPrefix: 'markets' });
  const quoteData = useMemo(() => {
    if (paddingData) return undefined;
    const isUp = data.isUp > 0;
    const title = `${data.name}`;
    const rate = `${data.isUp ? '+' : ''}${data.rate}%`;
    const { price, isOpen, originName } = data;
    return {
      title,
      isUp: isReverse ? !isUp : isUp,
      price,
      rate,
      isOpen,
      originName,
    };
  }, [quote, paddingData]);

  return quoteData ? (
    <div className={styles.datas}>
      {
        page === 'smallMarkets' ? (
          <>
            <div className={styles.nameLabel}>
              <img src={`https://nbt-ui.oss-cn-hongkong.aliyuncs.com/bt-ui/vmicon/${quoteData.originName}.svg`} alt="" />
              <span className={styles.symbol}>{quoteData.title}</span>
            </div>
            <div className={styles.priceLabel}>
              <span>
                $
                {quoteData.price}
              </span>
            </div>
            <div className={styles.changeLabel}>
              <span className={classnames(styles.priceRate, { [styles.rise]: quoteData.isUp })}>
                {quoteData.rate}
              </span>
            </div>
          </>
        ) : (
          <>
            {
              first && (
                <div className={data.index < 9 ? styles.ranking : styles.lastRanking}>
                  {data.index + 1}
                </div>
              )
            }
            <img src={`https://nbt-ui.oss-cn-hongkong.aliyuncs.com/bt-ui/vmicon/${quoteData.originName}.svg`} alt="" />
            {
              page === 'tradingData' ? (
                <div className={styles.tradingData}>
                  <span className={styles.title}>{quoteData.title}</span>
                  <span className={styles.price}>
                    $
                    {quoteData.price}
                  </span>
                  {quoteData.isOpen
                    ? <span className={classnames(styles.rate, { [styles.rise]: quoteData.isUp })}>{quoteData.rate}</span>
                    : <span className={classnames(styles.rate, styles.grey)}>{t('markets.label.close')}</span>}
                </div>
              ) : (
                <div className={styles.markets}>
                  <p>
                    <span className={styles.symbol}>{quoteData.title}</span>
                  </p>
                  <div className={styles.price}>
                    <span>
                      $
                      {quoteData.price}
                    </span>
                    <span className={classnames(styles.priceRate, { [styles.rise]: quoteData.isUp })}>{quoteData.rate}</span>
                  </div>
                </div>
              )
            }
          </>
        )
      }
    </div>
  ) : (
    <Skeleton />
  );
});

function Skeleton() {
  return (
    <div className={styles.skeleton}>
      <div className={styles.symbol} />
      <div className={styles.price} />
    </div>
  );
}

function TopGainers(props) {
  const { page, first } = props;
  const { tabList } = useQuoteTableData();
  const [currentWidth, setCurrentWidth] = useState(0);
  const { user } = useStore().auth;
  const { phrase, referral } = useH5StringBuilder();

  let className;
  if (page === 'markets') {
    className = styles.quoteColumn;
  } else if (page === 'tradingData') {
    className = classnames(styles.quoteColumn, styles.tradingData);
  } else if (page === 'smallMarkets') {
    className = styles.quoteColumnSmall;
  } else {
    className = styles.quoteRow;
  }

  useEffect(() => {
    const updateWindowWidth = () => {
      setCurrentWidth(window.innerWidth);
    };
    window.addEventListener('resize', updateWindowWidth);
    updateWindowWidth();
    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  function createSubarrays(originalArray, displaySize) {
    const newArray = [];
    for (let i = 0; i < originalArray.length; i += displaySize) {
      newArray.push(originalArray.slice(i, i + displaySize));
    }
    return newArray;
  }

  function jumpToPage(data) {
    let finalUrl = '';
    if (getPlatform() === 'pad' || getPlatform() === 'pc') {
      if (user) {
        finalUrl = `/trade/${data.code.includes('_CC') ? 'spot' : 'deal'}/${data.id}`;
      } else {
        finalUrl = `/trade/${data.code.includes('_CC') ? 'spotV2' : 'margin'}/${data.id}`;
      }
    } else {
      // reRouteTo(`${phrase}/${data.code.includes('_CC') ? 'trade' : `spotQuote/${data.id}`}`);
      finalUrl = `${phrase}/${data.code.includes('_CC') ? 'spotQuote' : 'trade'}/${data.id}${referral}`;
    }
    window.location.href = finalUrl;
  }

  const list = useMemo(() => {
    let listArray = [];
    let stash = [];
    let displaySize = 4;
    if (currentWidth < 400) {
      displaySize = 1;
    } else if (currentWidth < 768) {
      displaySize = 2;
    } else if (currentWidth < 1024) {
      displaySize = 3;
    } else {
      displaySize = 4;
    }

    tabList[0].forEach((content, index) => {
      const contentWithIndex = { ...content, index };
      stash.push(contentWithIndex);
      if (page === 'tradingData') {
        if (index && index < 10) {
          listArray.push(stash);
          stash = [];
        }
      } else if (page === 'markets' || page === 'smallMarkets') {
        if (index && index < 4) {
          listArray.push(stash);
          stash = [];
        }
      } else {
        listArray = createSubarrays(tabList[0], displaySize);
      }
    });
    return listArray;
  }, [tabList[0], currentWidth]);

  const renderQuote = (list || []).map((row, ind) => (
    <div key={`quote-row-${row[0]?.name}${ind}`}>
      {/* <div className={page === 'markets' ? styles.quoteColumn : page === 'tradingData' ? classnames(styles.quoteColumn, styles.tradingData) : styles.quoteRow}> */}
      <div className={className}>
        {row.map((data, index) => (
          <div
            key={`quote-${data?.name}${index}`}
            className={styles.quoteBrick}
            role="button"
            onClick={() => jumpToPage(data)}
            onKeyDown={() => jumpToPage(data)}
            tabIndex={0}
          >
            <Quote data={data} page={page} first={first} />
          </div>
        ))}
      </div>
    </div>
  ));

  const renderSkeleton = useMemo(() => (
    <div className={classnames(styles.quoteList, page === 'markets' || page === 'smallMarkets' || page === 'tradingData' ? styles.quoteColumn : styles.quoteRow)}>
      {new Array(page === 'markets' || page === 'smallMarkets' ? 4 : 4).fill('').map((_) => (
        <div key={`skeleton-${_}`}>
          <Skeleton />
        </div>
      ))}
    </div>
  ));

  if (page === 'markets' || page === 'smallMarkets' || page === 'tradingData') {
    return (
      <div className={styles.container}>
        {list.length ? (
          renderQuote
        ) : (
          renderSkeleton
        )}
      </div>
    );
  }
  return (
    <div className={styles.container}>
      {list.length ? (
        <Slider
          infinite
          autoplay
          // vertical
          // verticalSwiping
          swipeToSlide
          draggable={false}
          slidesToShow={1}
          slidesToScroll={1}
          className={styles.quoteList}
          arrows={false}
        >
          {renderQuote}
        </Slider>
      ) : (
        renderSkeleton
      )}
    </div>
  );
}

TopGainers.propTypes = {
  page: PropTypes.string.isRequired,
  first: PropTypes.bool.isRequired,
};

export default observer(TopGainers);
