import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
// import Slider from 'react-slick';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import { useStore } from '@/utils/store';
import useSymbolInfo from '@/utils/hooks/useSymbolInfo';
import useIndexData from '@/utils/hooks/useIndexData';
import { dayChangeRate } from '@/utils/functions/math';
import useQuoteTableData from '@/utils/hooks/useQuoteTableData';
import styles from './BasicInfo.module.scss';

const Quote = observer(({ symbol }) => {
  const symbolInfo = useSymbolInfo(symbol);
  const { currency: localCurrency } = useStore().symbol;
  const { isReverse } = useStore().setting;
  const { quote } = useStore().websocket;
  const paddingData = !symbolInfo || !quote;
  const quoteData = useMemo(() => {
    if (paddingData) return undefined;
    const {
      name, currency, contractCode, rate: exchangeRate,
    } = symbolInfo;
    const symbolQuote = quote?.[contractCode] || {};
    const isUp = symbolQuote.isUp > 0;
    const title = `${name.replace(currency, '')}/${currency}`;
    const rate = `${symbolQuote.prev < symbolQuote.price ? '+' : ''}${dayChangeRate(symbolQuote.prev, symbolQuote.price)}%`;
    const { price } = symbolQuote;
    const fiat = `${localCurrency === 'CNY' ? '¥' : '$'}${Number((symbolQuote.price * (exchangeRate || 1)).toFixed(8))}`;
    return {
      title,
      isUp: isReverse ? !isUp : isUp,
      price,
      rate,
      fiat,
    };
  }, [symbolInfo, quote, paddingData]);

  return quoteData ? (
    <>
      <p>
        <span className={styles.symbol}>{quoteData.title}</span>
        <span className={classnames(styles.priceRate, { [styles.rise]: quoteData.isUp })}>{quoteData.rate}</span>
      </p>
      <p className={styles.price}>
        $
        {quoteData.price}
      </p>
      <p className={styles.fiat}>{quoteData.fiat}</p>
    </>
  ) : (
    <Skeleton />
  );
});

Quote.propTypes = {
  symbol: PropTypes.string.isRequired,
};

function Skeleton() {
  return (
    <div className={styles.skeleton}>
      <div className={styles.symbol} />
      <div className={styles.price} />
      <div className={styles.fiat} />
    </div>
  );
}

function BasicInfo() {
  // const symbolStore = useStore().symbol;
  // const group = symbolStore.groups?.['市值榜'] || [];
  const { t } = useTranslation('pages', { keyPrefix: 'home' });

  const { spotArr, marginArr } = useQuoteTableData();
  const allMarginList = [];
  allMarginList.push(...marginArr[1]);
  allMarginList.push(...marginArr[2]);
  allMarginList.push(...marginArr[3]);
  let total24hVol = 0;

  let spotTotalVol = 0;
  spotArr[1].forEach((item) => {
    spotTotalVol += (parseFloat(item.volume) * parseFloat(item.price));
  });

  // let marginTotalVol = 0;
  // allMarginList.forEach((item) => {
  //   marginTotalVol += (parseFloat(item.volume) * parseFloat(item.price));
  // });

  total24hVol = parseInt(Number(spotTotalVol), 10) || 0;
  if (total24hVol >= 100000000) {
    total24hVol = `${parseFloat(Number(spotTotalVol) / (10 ** 8)).toFixed(2)}B+`;
  } else if (total24hVol >= 100000) {
    total24hVol = `${parseFloat(Number(spotTotalVol) / (10 ** 5)).toFixed(2)}M+`;
  }

  const result = useIndexData({});
  let monthTransactionVol = parseInt(Number(result.data), 10) || 0;
  if (monthTransactionVol >= 100000000) {
    monthTransactionVol = `${parseFloat(Number(result.data) / (10 ** 8)).toFixed(2)}B+`;
  } else if (monthTransactionVol >= 100000) {
    monthTransactionVol = `${parseFloat(Number(result.data) / (10 ** 5)).toFixed(2)}M+`;
  }

  // const list = useMemo(() => {
  //   const listArray = [];
  //   let stash = [];
  //   group.forEach((symbol, index) => {
  //     stash.push(symbol);
  //     if (index && (!((index + 1) % 4) || index === group.length - 1)) {
  //       listArray.push(stash);
  //       stash = [];
  //     }
  //   });
  //   return listArray;
  // }, [group]);

  // const renderQuote = (list || []).map((row) => (
  //   <div key={`quote-row-${row[0]}`}>
  //     <div className={styles.quoteRow}>
  //       {row.map((symbol) => (
  //         <div key={`quote-${symbol}`}>
  //           <Quote symbol={symbol} />
  //         </div>
  //       ))}
  //     </div>
  //   </div>
  // ));

  // const renderSkeleton = useMemo(() => (
  //   <div className={classnames(styles.quoteList, styles.quoteRow)}>
  //     {new Array(5).fill('').map((_, index) => (
  //       // eslint-disable-next-line react/no-array-index-key
  //       <div key={`skeleton-${index}`}>
  //         <Skeleton />
  //       </div>
  //     ))}
  //   </div>
  // ));

  const renderBasicInfo = useMemo(() => (
    <div className={styles.info}>
      <div>
        <p className={styles.data}>
          {total24hVol === 0 ? '-' : total24hVol}
        </p>
        <p className={styles.label}>{t('banner.basicInfo.tradingVol')}</p>
      </div>
      <div>
        <p className={styles.data}>
          {monthTransactionVol === 0 ? '-' : monthTransactionVol}
        </p>
        <p className={styles.label}>{t('banner.basicInfo.transactionVol')}</p>
      </div>
      <div>
        <p className={styles.data}>250+</p>
        <p className={styles.label}>{t('banner.basicInfo.products')}</p>
      </div>
      <div>
        <p className={styles.data}>1.25M</p>
        <p className={styles.label}>{t('banner.basicInfo.registeredUsers')}</p>
      </div>
    </div>
  ));

  return (
    <div className={styles.container}>
      { renderBasicInfo }
    </div>
  );
}

export default observer(BasicInfo);
