import { useMemo } from 'react';
import useQuoteTableData from '@/utils/hooks/useQuoteTableData';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { getPlatform } from '@/utils/functions/common';
import { useStore } from '@/utils/store';
import useH5StringBuilder from '@/utils/hooks/useH5StringBuilder';
import styles from './QuoteMarquee.module.scss';

const RenderQuote = ({ list, jumpToPage }) => (list || []).map((row) => (
  <div
    key={`quote-${row.symbol}`}
    className={styles.quoteBrick}
    role="button"
    onClick={() => jumpToPage(row.symbol)}
    onKeyDown={() => jumpToPage(row.symbol)}
    tabIndex={0}
  >
    <div className={styles.datas}>
      <img src={`https://nbt-ui.oss-cn-hongkong.aliyuncs.com/bt-ui/vmicon/${row.originName}.svg`} alt="" />
      <div>
        <span className={styles.symbol}>{row.currency ? `${row.name}/${row.currency}` : row.name}</span>
        <div className={styles.price}>
          <span>
            $
            {row.price}
          </span>
          <span className={classnames(styles.priceRate, { [styles.rise]: row.isUp })}>{`${row.rate}%`}</span>
        </div>
      </div>
    </div>
  </div>
));

function Skeleton() {
  return (
    <div className={styles.skeleton}>
      <div className={styles.symbol} />
      <div className={styles.price} />
    </div>
  );
}

function QuoteMarquee({ popularTab }) {
  const { tabList } = useQuoteTableData();
  const { user } = useStore().auth;
  const { phrase, referral } = useH5StringBuilder();

  let tab = 4;
  if (popularTab === 0) {
    tab = 4;
  } else if (popularTab === 1) {
    tab = 0;
  } else {
    tab = 2;
  }
  const listArray = tabList[tab];

  const jumpToPage = (data) => {
    let finalUrl = '';
    if (getPlatform() === 'pad' || getPlatform() === 'pc') {
      if (user) {
        finalUrl = `/trade/${data.includes('_CC') ? 'spot' : 'deal'}/${data}`;
      } else {
        finalUrl = `/trade/${data.includes('_CC') ? 'spotV2' : 'margin'}/${data}`;
      }
    } else {
      finalUrl = `${phrase}/${data.includes('_CC') ? 'spotQuote' : 'trade'}/${data}${referral}`;
    }
    window.location.href = finalUrl;
  };

  const renderSkeleton = useMemo(() => (
    <div className={classnames(styles.quoteList, styles.quoteRow)}>
      {new Array(4).fill('').map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`skeleton-${index}`}>
          <Skeleton />
        </div>
      ))}
    </div>
  ));

  return (
    <div className={styles.container}>
      {listArray?.length ? (
        <div className={styles.marquee}>
          <div className={popularTab === 0 ? styles.rotateMeDbl : styles.rotateMe}>
            <RenderQuote list={listArray} jumpToPage={jumpToPage} />
            <RenderQuote list={listArray} jumpToPage={jumpToPage} />
          </div>
        </div>
      ) : (
        renderSkeleton
      )}
    </div>
  );
}

QuoteMarquee.propTypes = {
  popularTab: PropTypes.number.isRequired,
};

export default observer(QuoteMarquee);
