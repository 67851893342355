import PropTypes from 'prop-types';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';
import { useCallback, useState } from 'react';

import { Button } from '@/components/common/Button';

import styles from './LicenseCard.module.scss';

function LicenseCard({
  img, country, id, date, link, isVertical,
}) {
  const { t } = useTranslation('pages', { keyPrefix: 'home.license.card' });
  const [show, setShow] = useState(false);

  const handleClick = () => window.open(link, '_blank').focus();

  const toggleShow = useCallback(() => {
    setShow((prevShow) => !prevShow);
  }, []);

  return (
    <section className={styles.container}>
      <div className={styles.licenseFrame} aria-hidden="true" onClick={toggleShow}>
        <figure className={classnames(styles.license, { [styles.vertical]: isVertical })}>
          <Image alt={id} layout="responsive" src={img} />
        </figure>
      </div>
      <p className={styles.country}>
        {country}
        <span>{t('supervised')}</span>
      </p>

      <div className={styles.licenseInfo}>
        <p>
          <span>{t('id')}</span>
          <span>{id}</span>
        </p>
        <p>
          <span>{t('issueDate')}</span>
          <span>{date}</span>
        </p>
      </div>

      <div className={classnames(styles.imageView, { [styles.modelShow]: show })} aria-hidden="true" onClick={toggleShow}>
        <figure>
          <Image alt={`${id}-view`} layout="fill" objectFit="contain" src={img} />
        </figure>
      </div>

      <Button className={styles.link} onClick={handleClick}>
        <a href={link}>{`${country} ${t('verification')}`}</a>
      </Button>
    </section>
  );
}

LicenseCard.propTypes = {
  img: PropTypes.instanceOf(Object).isRequired,
  country: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  isVertical: PropTypes.bool,
};

LicenseCard.defaultProps = {
  isVertical: false,
};

export default LicenseCard;
